// General

$bgcolor: #d4ede7;
$textcolor: #222222;
$fontsize: 16px;
$border: 3px solid $textcolor;
$padding: 10px 20px 10px 20px;

@mixin default_text {
    color: $textcolor;
    font: {
        family: 'Roboto';
        size: $fontsize;
        weight: 500;
    }
}

@mixin text_header {
    font: {
        family: 'Roboto';
        size: 30px;
        weight: 700;
    }
}

@mixin shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    border-radius: 10px;
}

@mixin button_shadow {
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);  
}

html {
    box-sizing: border-box;
    background-color: $bgcolor;
    //user-select: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    overscroll-behavior: contain;
}

body a  {
    text-decoration: none;
}

#root, #main-container {
    min-height: 100vh;
}

input:focus, textarea:focus  {
    outline: none;
    box-shadow: 0px 0px 5px #02997d;
}

#accuracy-slider:focus  {
    outline: none;
    box-shadow: 0px 0px 5px transparent;
}

#main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

#main-wrapper  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

// Login

/* Intro */

#intro-outer-container  {
    flex: 1;
    display: flex;
    padding: 25px;
    max-height: 100vh;
    justify-content: center;
}

#intro-inner-container  {
    flex: 1;
    max-width: 450px;
    //background-image: url('../images/logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.logo  {
    max-width: 100%;
    max-height: 100%;
}

#primary-login {
  background-color: #78b6a7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: #000;
  cursor: pointer;
  display: block;
  max-width: 156px;
  font-family: 'Roboto';
  font-weight: 600;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}

#primary-login:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
}

/* Login form */

#login-inner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
    padding: 25px;
    overflow: hidden;
}

#login-inner-1 {		
	background: linear-gradient(90deg, #78b6a7, #63ad9b);		
	position: relative;	
    min-height: 100%;
	box-shadow: 0px 0px 24px #508477;
}

.login-inner-2 {
	z-index: 1;
	position: relative;	
	height: 100%;
}

#login-background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.login-background-shape {
	transform: rotate(45deg);
	position: absolute;
}

#login-background-shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

#login-background-shape2 {
	height: 220px;
	width: 220px;
	background: #358e78;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

#login-background-shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #358e78, #55aa95);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

#login-background-shape4 {
	height: 400px;
	width: 200px;
	background: #358e78;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

#login, #remind, #reset {
	width: 320px;
	padding: 30px;
	padding-top: 150px;
    padding-bottom: 150px;
}

.login-field {
	padding: 20px 0px;	
	position: relative;	
}

.login-form {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login-form:active,
.login-form:focus,
.login-form:hover {
	outline: none;
	border-bottom-color: #6b978c;
}

.send-login {
	//background: #fff;
    background-color: #7fff9f;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	//color: #4a7b6f;
    color: #222;
	box-shadow: 0px 2px 2px #508477;
	cursor: pointer;
	transition: .2s;
    letter-spacing: 1px;
}

.login-button-wrapper  {
    position: relative;
}

.login-message  {
    background-color: #fff;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%;
    text-align: center;
    color: #009f70;
    font-weight: 600;
    padding: 5px;
    border: 2px solid #009f70;
}

.send-login:active,
.send-login:focus,
.send-login:hover {
	border-color: #6b978c;
	outline: none;
}

.send-login-text  {
    display: block;
    width: 100%;
    text-align: center;
}

// Przypomnienie hasła

.login-screen-change  {
    display: block;
    margin: auto;
    margin-top: 50px;
    padding: 10px 15px;
    //background: #fff;
    background: #ffe8cb;
	font-size: 14px;
	border-radius: 5px;
	border: 0;
    color: #4d4d4d;
	text-transform: uppercase;
	font-weight: 700;
    border: 1px solid #868689;
	box-shadow: 0px 2px 2px #508477;
	cursor: pointer;
	transition: .2s;
    letter-spacing: 0.3px;
    font-family: 'roboto';
}

#remind-message  {
    font-family: 'roboto';
    font-size: 14px;
    line-height: 18.8px;
    font-weight: 700;
    margin: 0;
}

#input-hidden  {
    visibility: hidden;
}

// Modal

.modal-overlay  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(25, 25, 25, 0.6);
    z-index: 1200;
    backdrop-filter: blur(1px);
}

.modal  {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 25px;
    min-width: 380px;
    max-width: 95vw;
    max-height: 90vh;
    border-radius: 15px;
    user-select: none;
}

.modal-header  {
    padding: 25px;
    background-color: #1fd075;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.modal-header-error  {
    background-color: #c73f3f;
}

.modal-title  {
    @include default_text;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    flex-basis: 80%;
    margin: 0;
    text-align: center;
    color: #fff;
}

.modal-body  {
    overflow-y: auto;
}

.modal-wrapper  {
    padding: 10px 20px 0px 20px;
}

.modal-text {
    @include default_text();
    margin-bottom: 5px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
}

.modal-footer  {
    margin-left: 10px;
    margin-right: 10px;
}

.modal-single-button-wrapper {
    text-align: center;
    margin-top: 15px;
}

.modal-single-button  {
    border: 0;
    @include default_text();
    @include button_shadow();
    font-size: 14px;
    padding: 8px;
    background-color: #1fd075;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    margin-left: 25px;
    margin-right: 25px;
    cursor: pointer;
    min-width: 60px;
}

.modal-single-error-button  {
    background-color: #c73f3f;
}

// Responsywne

@media screen and (max-width: 1400px) {
    .admin-panel-main-menu-container  {
        flex-direction: column;
    }
}

#app-outer-container  {
    width: 100%;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#app-inner-container  {
    @include shadow();
    background-color: #fff;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#phone, #otp  {
    width: 200px;
    margin-top: 10px;
}

.send-login-2  {
    margin-top: 10px;
}

#error-text  {
    @include default_text();
    color: #ff0000;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 0;
}


